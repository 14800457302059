import type { AppProps } from 'next/app'
import { ApolloProvider } from '@apollo/client'
import { client } from '@/graphql/client'

import { LocalizationProvider } from '@mui/lab'
import DateAdapter from '@mui/lab/AdapterDayjs'
import { ThemeProvider as MUThemeProvider } from '@mui/material/styles'
import theme from '@/styles/theme'

import '@/styles/destyle.css'
import '@/styles/globals.css'

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <ApolloProvider client={client}>
        <MUThemeProvider theme={theme}>
          <Component {...pageProps} />
        </MUThemeProvider>
      </ApolloProvider>
    </LocalizationProvider>
  )
}

export default MyApp
